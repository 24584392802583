import * as React from "react";
import { navigate } from "gatsby";
import { useEffect } from "react";

const TraitorousNumberPage = () => {
  useEffect(() => {
    navigate("/#traitorous-number", { replace: true });
  }, []);
  return null;
};

export default TraitorousNumberPage;
